import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      backend: {
        loadPath: `../../locales/{{lng}}.json`,
      },
      react: {
        useSuspense: true,
      },
      fallbackLng: 'en',
      preload: ['zh-CN'],
      keySeparator: false,
      interpolation: { escapeValue: false },
      detection: {
        order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage', 'cookie'], // Make sure this line is present
      },
    },
    function (err, t) {
      // 初始化完成后的回调
      // 可以在这里设置当前语言，比如基于URL参数或者用户的选择
    }
  )

export default i18next
